

:deep().el-autocomplete{
    width: 100%!important;
    font-weight: 400;
    line-height: 1.5;
    .el-input__inner{
        height: 34px !important;
        padding: 0px 10px !important;
        border: 1px solid #c6dcf5 !important;
        border-radius: 5px !important;
        background-color: #ffffff !important;
        font-size: 13px !important;
        color: #2b2b2d !important;
        -webkit-text-decoration: none solid #2b2b2d !important;
        text-decoration: none solid #2b2b2d !important;
        box-shadow: inset 0 1px 3px rgba(1 ,36 ,84 , 30%) !important;
    }
}
.btn-email-link:hover{
    border-color: transparent;
    background-color: transparent;
    text-decoration: underline;
}

